import cookieManager from '@/store/cookieManager';

export const roleManager = {
  isAdmin() {
    return cookieManager.get('role') === 'Admin'
    && !cookieManager.get('is_staff', Boolean);
  },
  isAnyAdmin() {
    return cookieManager.get('role') === 'Admin';
  },
  isClient() {
    return cookieManager.get('role') === 'Client';
  },
  isEditor() {
    return cookieManager.get('role') === 'Editor'
    || cookieManager.get('role') === 'EditorApprover';
  },
  isApprover() {
    return cookieManager.get('role') === 'Approver'
    || cookieManager.get('role') === 'EditorApprover';
  },
  isStaff() {
    return cookieManager.get('role') === 'Admin'
    && cookieManager.get('is_staff', Boolean);
  },
  canUpload() {
    return this.isAnyAdmin() || this.isEditor();
  },
  canApprove() {
    return this.isAdmin() || this.isApprover();
  },
  roleList() {
    return ['Admin', 'Client', 'Approver', 'Editor', 'EditorApprover'];
  },
};
